<template>
<div>
  <vue-dropzone id="uploadProductVideoDrop" ref="uploadProductVideoDrop"
    :use-custom-slot="true" :maxFiles="1" 
    acceptedFiles="video/*,.mp4,.bmp,.m4v,.avi,.mpg,.mp4">
      <div class="needsclick m-0">
        <span class="bx bx-cloud-upload fs-1"></span>
        <h6> Drop files here or click to upload. </h6>
        <span class="text-muted fs-13">
            (Should be only a Video)
        </span>
      </div>
  </vue-dropzone>
  <div class="d-flex mt-3 justify-content-end">
    <b-button variant="primary" @click.prevent="upload()" type="button">Upload</b-button>
  </div>
</div>
</template>

<script>
import VueDropzone from '../VueDropzone.vue';
export default {
  components: { VueDropzone },
  props:{
    productId:{
      type: Number,
      default: null
    }
  },
  methods: {
    upload(){
      if(!this.collectFiles().length){
        this.alertError("A video file is required"); return;
      }
      let formData = new FormData();
      formData.append('video', this.collectFiles()[0])
      this.$store.dispatch("changeLoaderValue", true)
      this.$http.post(`/portfolios/${this.productId}/video`,formData)
      .then((response) => {
        this.$store.dispatch("changeLoaderValue", false)
        if(response.data.success){
          this.$store.commit("productList/SET_PORTFOLIOS", response.data.data.portfolios)
          this.resetAll()
        }
      })
    },
    collectFiles(){
      var arrafile = [];
      if(this.$refs.uploadProductVideoDrop){
        arrafile = [...this.$refs.uploadProductVideoDrop.getAcceptedFiles()];
      }
      return arrafile
    },
    resetAll(){
      if(this.$refs.uploadProductVideoDrop){
        this.$refs.uploadProductVideoDrop.removeAllFiles();
      }
      this.$emit('closeMe');
    },
  },
}
</script>
