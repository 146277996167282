<template>
    <div class="requirement-card">
        <small class="text-muted mb-0"> ({{item.type.replace(/_/g, ' ')}})</small>
        <h5>{{item.title}}</h5>
        <div class="mb-1">{{item.description}}</div>
        <div class="d-sm-flex">
            <div class="form-check me-2">
                <input disabled type="checkbox" :checked="item.is_active" class="form-check-input">
                <label class="form-check-label" >Active</label>
            </div>
            <div class="form-check me-2">
                <input disabled type="checkbox" :checked="item.multiple" class="form-check-input">
                <label class="form-check-label" >Multiple</label>
            </div>
            <div class="form-check">
                <input disabled type="checkbox" :checked="item.required" class="form-check-input">
                <label class="form-check-label" >Required</label>
            </div>
        </div>
        <div class="mt-3">
            <slot name="actions"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        item:{
            type: Object,
            required: true
        }
    }
}
</script>