<template>
     <form autocomplete="off" class="">
        <div class="row g-3">
            <div class="col-md-6">
                <input type="text" v-model="data.name"
                    :class="{ 'is-invalid': v$.data.name.$error }"
                    id="inputName" name="name" class="form-control"
                    placeholder="Enter name" />
                <div v-for="(item, index) in v$.data.name.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <input type="text" v-model="data.value"
                    :class="{ 'is-invalid': v$.data.value.$error }"
                    id="inputValue" name="value" class="form-control"
                    placeholder="Value or color codes" />
                <div v-for="(item, index) in v$.data.value.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
                <small @click="is_photo_gallery = !is_photo_gallery" >
                    <span v-if="!is_photo_gallery" class="text-success cursor-pointer">Click to add file</span>
                    <span v-else class="text-secondary cursor-pointer">Click to hide uploader</span>
                     for photo gallery
                </small>
            </div>
            <div class="col-md-6">
                <input type="text" v-model="data.description"
                    name="description" class="form-control"
                    placeholder="Write description" />
            </div>
            <div class="col-lg-6">
                <b-form-checkbox v-model="data.default" name="default" switch>
                    Default Selection
                </b-form-checkbox>
            </div>
            <div v-if="is_photo_gallery" class="col-12">
                <vue-dropzone id="reqValeUploadDrop" ref="reqValeUploadDrop"
                :use-custom-slot="true" :maxFiles="1" >
                <div class="needsclick m-0">
                    <span class="bx bx-cloud-upload fs-1"></span>
                    <h6> {{editMode? 'Add to update image (photo gallery)':'Drop file or click to add'}}</h6>
                    <span class="text-muted fs-13">
                        (500x500 pixels dimension recommended)
                    </span>
                </div>
                </vue-dropzone>
            </div>
            <div class="col-12">
                <div class="d-flex align-items-center justify-content-end">
                    <b-button class="me-2" variant="primary" 
                    @click.prevent="editMode? update() : create()"
                     type="button">{{editMode? 'Update':'Submit'}}</b-button>
                     <b-button variant="danger" 
                    @click.prevent="$emit('exit')"
                     type="button" >Exit Form</b-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import VueDropzone from "../VueDropzone.vue";
import { required, requiredIf} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components:{
        VueDropzone,
    },
    props:{
        value:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        },
        requirementId:{
            type: Number,
            default: false
        }
    },
    data(){
        return{
            is_photo_gallery: false,
            data: {
                requirement_id: this.requirementId,
                name: "",
                value: "",
                default: false,
                description: "",
            },
        }
    },
    validations() {
        return {
            data:{
                name:{ required: required},
                value: {
                    requiredIf: requiredIf(!this.is_photo_gallery),
                },
            }
        }
    },
    methods:{
        create() {
            this.v$.$validate().then(result =>{
                if (!result) return;
                const formData = new FormData()
                if(this.is_photo_gallery){
                    if(!this.collectFiles().length){this.alertError("add image file"); return;}
                    formData.append('image_file', this.collectFiles()[0])
                }
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/product-requirement-values/create`, formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$emit("created", response.data.data.value)
                        this.resetForm();
                    }
                })
            })
        },
        update(){
           this.v$.$validate().then(result =>{
                if (!result) return;
                const formData = new FormData()
                if(this.is_photo_gallery && this.collectFiles().length){
                    formData.append('image_file', this.collectFiles()[0])
                }
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/product-requirement-values/${this.data.id}/update`,formData)
                    .then((response) => {
                        this.$store.dispatch("changeLoaderValue")
                        if(response.data.success){
                            this.$emit("updated", response.data.data.value)
                            this.resetForm()
                        }
                    })
            
            })
        },
        collectFiles(){
            let arrafile = [];
            if(this.$refs.reqValeUploadDrop){
                arrafile = [...this.$refs.reqValeUploadDrop.getAcceptedFiles()];
            }
            return arrafile
        },
        resetForm(){
            this.v$.$reset()
        }
    },
    mounted(){
        if(this.editMode){
            this.data = {...this.value}
        }
    }
}
</script>