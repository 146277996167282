<template>
    <form autocomplete="off" class="">
        <div class="row g-3">
            <div class="col-md-6">
                <label for="inputName">
                    Name <span class="text-danger small">*</span>
                </label>
                <input type="text" v-model="data.name"
                    :class="{ 'is-invalid': v$.data.name.$error }"
                    id="inputName" name="name" class="form-control"
                    placeholder="Enter name" />
                <div v-for="(item, index) in v$.data.name.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <label class="form-label">
                    Charge <span class="text-danger">*</span>
                </label>
                <input type="number" v-model="data.charge" class="form-control"
                name="charge" :class="{ 'is-invalid': v$.data.charge.$error }" placeholder="Enter charge" />
                <div v-for="(item, index) in v$.data.charge.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-12">
                <label class="form-label">
                    Duration <span class="text-danger">*</span>
                </label>
                <select v-model="data.duration"
                    class="form-control" placeholder="--select day--"
                    :class="{ 'is-invalid': v$.data.duration.$error }">
                    <option selected value="" >--select day--</option>
                    <option v-for="(dTime, dIndex) in deliveryDays" :key="dIndex"
                    :value="dTime.value" >{{dTime.text}}</option>
                </select>
                    <div v-for="(item, index) in v$.data.duration.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-12">
                <label class="form-label">Description</label>
                <textarea v-model="data.description" class="form-control"
                rows="2" placeholder="Write Description" name="description"></textarea>
            </div>
            <div class="col-12">
                <div class="d-flex justify-content-end">
                    <b-button variant="primary" 
                    @click.prevent="editMode? update() : create()"
                     type="button">{{editMode? 'Update':'Submit'}}</b-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    props:{
        productId:{
            type: Number,
            default: null
        },
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            data: {
                name: "",
                charge: "",
                duration: "",
                description: "",
                product_id: this.productId
            },
        }
    },
    validations: {
        data:{
            name: { required: required},
            charge: { required:  required},
            duration: { required:  required}
        }
    },
    computed:{
        deliveryDays(){
            let days = [{value: 24, text: "1 day"}];
            for (let step = 2; step < 101; step++) {
                days.push({value: step*24, text: `${step} days`});
            }
            return days;
        }
    },
    methods:{
        create() {
            this.v$.$validate().then(result =>{
                if (!result) return;
                this.$store.dispatch("changeLoaderValue", true)
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$http.post("/addons/create", formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$store.commit("productList/ADD_ADDON", response.data.data.addon)
                        this.resetForm();
                    }
                })
            })
        },
        update(){
            this.v$.$validate().then(result =>{
                if (!result) return;
                this.$store.dispatch("changeLoaderValue", true)
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$http.post(`/addons/${this.editItem.id}/update`,formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$store.commit("productList/UPDATE_ADDON", response.data.data.addon)
                        this.resetForm()
                    }
                })
            
            })
        },
        resetForm(){
            this.v$.$reset()
            this.$emit("closeMe")
        }
    },
    mounted(){
        if(this.editMode){
            this.data = JSON.parse(JSON.stringify(this.editItem))
        }
    }
}
</script>