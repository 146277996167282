<template>
    <form autocomplete="off" class="">
        <div class="row g-3">
            <div class="col-12">
                <label>
                    Question <span class="text-danger small">*</span>
                </label>
                <input type="text" v-model="data.question"
                    :class="{ 'is-invalid': v$.data.question.$error }"
                    name="question" class="form-control"
                    placeholder="Enter question" />
                <div v-for="(item, index) in v$.data.question.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-12">
                <label class="form-label">Answer</label>
                <textarea v-model="data.answer" class="form-control"
                :class="{ 'is-invalid': v$.data.answer.$error }"
                rows="5" placeholder="Write answer" name="answer"></textarea>
                <div v-for="(item, index) in v$.data.answer.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-12">
                <div class="d-flex justify-content-end">
                    <b-button variant="primary" 
                    @click.prevent="editMode? update() : create()"
                     type="button">{{editMode? 'Update':'Submit'}}</b-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    props:{
        productId:{
            type: Number,
            default: null
        },
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            data: {
                question: "",
                answer: "",
                product_id: this.productId
            },
        }
    },
    validations: {
        data:{
            question: { required:  required},
            answer: { required:  required}
        }
    },
    methods:{
        create() {
            this.v$.$validate().then(result =>{
                if (!result) return;
                this.$store.dispatch("changeLoaderValue", true)
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$http.post("/product-questions/create", formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$store.commit("productList/ADD_QUESTION", response.data.data.question)
                        this.resetForm();
                    }
                })
            })
        },
        update(){
            this.v$.$validate().then(result =>{
                if (!result) return;
                this.$store.dispatch("changeLoaderValue", true)
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$http.post(`/product-questions/${this.editItem.id}/update`,formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$store.commit("productList/UPDATE_QUESTION", response.data.data.question)
                        this.resetForm()
                    }
                })
            
            })
        },
        resetForm(){
            this.v$.$reset()
            this.$emit("closeMe")
        }
    },
    mounted(){
        if(this.editMode){
            this.data = JSON.parse(JSON.stringify(this.editItem))
        }
    }
}
</script>