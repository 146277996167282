<template>
  <form autocomplete="off" class="">
    <div class="row g-3 g-lg-4">
      <div class="col-12">
        <label class="form-label" for="input_title">Meta title</label>
        <input type="text" v-model="data.meta_title" id="input_title" name="title" class="form-control" placeholder="Enter meta title" />
      </div>
      <div class="col-12">
        <label class="form-label" for="input_meta_keys">Meta keys</label>
        <input type="text" v-model="data.meta_keys" id="input_meta_keys" name="meta-keys" class="form-control" placeholder="e.g: Logo Design, Letterhead, Poster Design" />
      </div>
      <div class="col-12">
        <label class="form-label" for="meta_desc">Meta Description</label>
        <textarea id="meta_desc" v-model="data.meta_desc" class="form-control" rows="3" placeholder="Write meta description" name="meta-desc"></textarea>
      </div>
      <div class="col-12">
        <label class="form-label" for="short_desc">Short description</label>
        <textarea id="short_desc" v-model="data.short_description" class="form-control" rows="3" placeholder="Write short description" name="short_desc"></textarea>
      </div>
      <div class="col-12">
        <label class="form-label" for="main_description">Main description</label>
        <textarea id="main_description" v-model="data.description" class="form-control" rows="6" placeholder="Write main description" name="description"></textarea>
      </div>
      <div class="col-12">
        <div class="d-flex mt-3 justify-content-end">
          <b-button variant="primary" 
          @click.prevent="update()"
            type="button">Save Changes</b-button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    props:{
      product:{
        type: Object,
        default: null
      },
    },
    data(){
        return{
          data: {},
        }
    },
    methods:{
        update(){
            this.v$.$validate().then(result =>{
                if (!result) return;
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/products/${this.product.id}/update-metas`,formData)
                    .then((response) => {
                        this.$store.dispatch("changeLoaderValue", false)
                        if(response.data.success){
                            this.$store.commit("productList/UPDATE_PRODUCT", response.data.data.product)
                            this.resetForm()
                        }
                    })
            
            })
        },
        patchUpdatables() {
          if(this.product.description){
            this.data = {
              meta_title: this.product.description.meta_title,
              meta_keys: this.product.description.meta_keys,
              meta_desc: this.product.description.meta_desc,
              short_description: this.product.description.short_description,
              description: this.product.description.description,
            }
          }
        },
    },
    mounted(){
      this.patchUpdatables()
    }
}
</script>