<template>
     <form autocomplete="off" class="">
        <div class="row g-3">
            <div class="col-12">
                <label for="inputName">
                    Name <span class="text-danger small">*</span>
                </label>
                <input type="text" v-model="data.name"
                    :class="{ 'is-invalid': v$.data.name.$error }"
                    id="inputName" name="name" class="form-control"
                    placeholder="Give it a name" />
                <div v-for="(item, index) in v$.data.name.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-12">
                <label for="inputTitle">
                    Title <span class="text-danger small">*</span>
                </label>
                <input type="text" v-model="data.title"
                    :class="{ 'is-invalid': v$.data.title.$error }"
                    id="inputTitle" name="title" class="form-control"
                    placeholder="Enter question" />
                <div v-for="(item, index) in v$.data.title.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-12 col-md-4 col-lg-6">
                <label class="me-3">Sort Order</label>
                <div class="input-step step-primary">
                    <button @click="data.sort_order > 1 ? data.sort_order-- : false" type="button" class="minus">–</button>
                    <input v-model="data.sort_order" type="number" class="product-quantity" min="0" max="100"/>
                    <button @click="data.sort_order > 99 ? false : data.sort_order++" type="button" class="plus">+</button>
                </div>
            </div>
            <div class="col-12">
                <label class="">Type</label>
                <select class="form-select" v-model="data.type"
                     :class="{ 'is-invalid': v$.data.type.$error }"
                    name="type" placeholder="--select type--">
                    <option disabled value="">--select type--</option>
                    <option value="pro_input">Simple Input</option>
                    <option value="pro_textarea">Textarea</option>
                    <option value="pro_checkbox">Checkbox</option>
                    <option value="pro_select">Select Input</option>
                    <option value="pro_radio">Radio Input</option>
                    <option value="photo_gallery">Photo Gallery</option>
                    <option value="file_uploader">File Uploader</option>
                    <option value="color_gallery">Color Gallery</option>
                </select>
                <div v-for="(item, index) in v$.data.type.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-6 col-md-4">
                <b-form-checkbox v-model="data.is_active" name="is_active" switch>
                    Visibility
                </b-form-checkbox>
            </div>
            <div class="col-6 col-md-4">
                <b-form-checkbox v-model="data.multiple" name="multiple" switch>
                    Multiple
                </b-form-checkbox>
            </div>
            <div class="col-6 col-md-4">
                <b-form-checkbox v-model="data.required" name="multiple" switch>
                    Required
                </b-form-checkbox>
            </div>
            <div v-if="data.required" class="col-12">
                <label for="required_text">
                    Required text
                    <span class="text-danger small">*</span>
                </label>
                <input type="text" v-model="data.required_text"
                    :class="{ 'is-invalid': v$.data.name.$error }"
                    id="required_text" class="form-control" name="required_text" placeholder="required text" />
                 <div v-for="(item, index) in v$.data.name.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-12">
                <label class="form-label" for="description">Description</label>
                <textarea id="description"
                    v-model="data.description" class="form-control" rows="3"
                    placeholder="Write description" name="description"></textarea>
            </div>
            <div class="col-12">
                <div class="d-flex justify-content-end">
                    <b-button variant="primary" 
                    @click.prevent="editMode? update() : create()"
                     type="button">{{editMode? 'Update':'Submit'}}</b-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, helpers, requiredIf} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    props:{
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        },
        productId:{
            type: Number,
            default: null
        }
    },
    data(){
        return{
            data: {
                product_id: this.productId,
                name: "",
                title: "",
                type: "",
                kind: "",
                required_text: "",
                required: false,
                multiple: false,
                is_active: true,
                sort_order: 1,
                description: ""
            },
        }
    },
    validations() {
        return {
            data:{
                name:{ required: required},
                title:{ required: required},
                type:{ required: required},
                required_text: {
                    requiredIf: requiredIf(this.data.required),
                },
            }
        }
    },
    methods:{
        create() {
            this.v$.$validate().then(result =>{
                if (!result) { this.alertError('Form not valid'); return; }
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/product-requirements/create`, formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue")
                    if(response.data.success){
                        this.$store.commit("productList/ADD_REQUIREMENT", response.data.data.requirement)
                        this.resetForm();
                    }
                })
            })
        },
        update(){
            this.v$.$validate().then(result =>{
                if (!result) { this.alertError('Form not valid'); return; }
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/product-requirements/${this.editItem.id}/update`,formData)
                    .then((response) => {
                        this.$store.dispatch("changeLoaderValue")
                        if(response.data.success){
                            this.$store.commit("productList/UPDATE_REQUIREMENT", response.data.data.requirement)
                            this.resetForm()
                        }
                    })
            
            })
        },
        resetForm(){
            this.v$.$reset()
            this.$emit("closeMe")
        },
        patchUpdatables(){
            let allowed = Object.keys(this.data);
            return Object.keys(this.editItem)
                .filter(key => allowed.includes(key))
                .reduce((obj, key) => {
                    obj[key] = this.editItem[key];
                    return obj;
                }, {});
        }
    },
    mounted(){
        if(this.editMode){
            this.data = JSON.parse(JSON.stringify(this.patchUpdatables()))
        }
    }
}
</script>