<template>
    <form autocomplete="off" class="">
        <div class="row g-3 g-lg-4">
            <div class="col-md-6">
                <label for="inputName">
                    Name <span class="text-danger small">*</span>
                </label>
                <input type="text" v-model="data.name"
                    :class="{ 'is-invalid': v$.data.name.$error }"
                    id="inputName" name="name" class="form-control"
                    placeholder="Enter name" />
                <div v-for="(item, index) in v$.data.name.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            
            <div class="col-md-6">
                <label class="form-label">Category</label>
                <select class="form-select" v-model="data.category_id"
                :class="{ 'is-invalid': v$.data.category_id.$error }"
                    name="type" placeholder="--select type--">
                    <option disabled value="">--select category--</option>
                    <option v-for="(category, cIndex) in categories" :key="cIndex"
                     :value="category.id">{{category.name}}</option>
                </select>
                    <div v-for="(item, index) in v$.data.category_id.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <label class="form-label">
                    Price <span class="text-danger">*</span>
                </label>
                <input type="number" v-model="data.price" class="form-control"
                name="price" :class="{ 'is-invalid': v$.data.price.$error }" placeholder="Enter price" />
                <div v-for="(item, index) in v$.data.price.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <label for="inputSlug">
                    Slug <span class="text-danger small">*</span>
                </label>
                <input type="text" v-model="data.slug"
                    :class="{ 'is-invalid': v$.data.slug.$error }"
                    id="inputSlug" name="slug" class="form-control"
                    placeholder="Enter slug" />
                <div v-for="(item, index) in v$.data.slug.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-6 col-md-3">
                <b-form-checkbox v-model="data.is_active" name="is_active" switch>
                    Active
                </b-form-checkbox>
            </div>
            <div class="col-6 col-md-3">
                <b-form-checkbox v-model="data.is_featured" name="is_featured" switch>
                     Featured
                </b-form-checkbox>
            </div> 
            <div class="col-12 col-md-4 col-lg-6">
                <label class="me-3">Position</label>
                <div class="input-step step-primary">
                    <button @click="data.sort_order > 1 ? data.sort_order-- : false" type="button" class="minus">–</button>
                    <input v-model="data.sort_order" type="number" class="product-quantity" min="0" max="100"/>
                    <button @click="data.sort_order > 99 ? false : data.sort_order++" type="button" class="plus">+</button>
                </div>
            </div>
            <div v-if="editMode" class="col-6 col-md-4 col-lg-2">
                <img class="w-100 mb-2" :src="absoluteUrl(editItem.image)">
                <p class="small">Product image</p>
            </div>
            <div class="col-12" :class="{'col-sm-8 col-lg-9 offset-lg-1':editMode}">
                <vue-dropzone id="productUploadDrop" ref="productUploadDrop"
                :use-custom-slot="true" :maxFiles="1" >
                <div class="needsclick m-0">
                    <span class="bx bx-cloud-upload fs-1"></span>
                    <h6> {{editMode? 'Add file to update':'Drop file or click to add'}}</h6>
                    <span class="text-muted fs-13">
                        (500x500 pixels dimension recommended )
                    </span>
                </div>
                </vue-dropzone>
            </div>
            <div class="col-12">
                <div class="d-flex mt-3 justify-content-end">
                    <b-button variant="primary" 
                    @click.prevent="editMode? update() : create()"
                     type="button">{{editMode? 'Update':'Submit'}}</b-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import VueDropzone from "../VueDropzone.vue";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components:{
        VueDropzone,
    },
    props:{
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            data: {
                name:"",
                category_id:"",
                price: null,
                slug:"",
                sort_order: 1,
                is_active: true,
                is_featured: false,
            },
        }
    },
    watch: {
        'data.name': function (name){
            if(this.editMode) return;
            this.data.slug = name.toString().normalize('NFD').replace(/[\u0300-\u036f]/g,'').replace(/\s+/g,'-').toLowerCase().replace(/&/g,'-and-').replace(/[^a-z0-9\-]/g,'').replace(/-+/g,'-').replace(/^-*/,'').replace(/-*$/,'');
        },
    },
    validations() {
        return {
            data:{
                name:{required: required},
                category_id:{required: required},
                price:{required: required},
                slug:{required: required},
                sort_order:{required: required},
                is_active:{required: required},
                is_featured:{required: required},
            }
        }
    },
    computed:{
        categories() {
            return this.$store.state.categoryList.categories
        }
    },
    methods:{
        create() {
            this.v$.$validate().then(result =>{
                if (!result) return;
                if(!this.collectFiles().length){ this.alertError("Please a add product image"); return;}
                let formData = new FormData();
                formData.append('image_file', this.collectFiles()[0])
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/products/create`, formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$store.commit("productList/ADD_PRODUCT", response.data.data.product)
                        this.resetForm();
                    }
                })
            })
        },
        update(){
            this.v$.$validate().then(result =>{
                if (!result) return;
                const formData = new FormData()
                if(this.collectFiles().length){
                    formData.append('image_file', this.collectFiles()[0])
                }
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/products/${this.editItem.id}/update`,formData)
                    .then((response) => {
                        this.$store.dispatch("changeLoaderValue", false)
                        if(response.data.success){
                            this.$store.commit("productList/UPDATE_PRODUCT", response.data.data.product)
                            this.resetForm()
                        }
                    })
            
            })
        },
        collectFiles(){
            let arrafile = [];
            if(this.$refs.productUploadDrop){
                arrafile = [...this.$refs.productUploadDrop.getAcceptedFiles()];
            }
            return arrafile
        },
        resetForm(){
            this.$refs.productUploadDrop.removeAllFiles();
            this.v$.$reset()
            this.$emit("closeMe")
        },
        patchUpdatables(){
            let allowed = Object.keys(this.data);
            return Object.keys(this.editItem)
                .filter(key => allowed.includes(key))
                .reduce((obj, key) => {
                    obj[key] = this.editItem[key];
                    return obj;
                }, {});
        }
    },
    mounted(){
        if(this.editMode){
            this.data = JSON.parse(JSON.stringify(this.patchUpdatables()))
        }
    }
}
</script>