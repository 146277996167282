<template>
  <div>
    <div class="row">
      <div class="col-12">
        <ul class="list-group">
          <li class="list-group-item" v-for="(portfolio, index) in paginatedPortfolios" :key="index" >
            <div class="row">
              <div class="col-sm-4 mb-2 mb-md-0" @click="() => showImg(portfolio.id)" >
                <span class="d-inline-block position-relative">
                  <span v-if="portfolio.is_video" class="video-icon-dp">
                    <i class="ri-play-circle-line"></i>
                  </span>
                  <b-img thumbnail class="me-3 cursor-pointer" fluid :src="absoluteUrl(portfolio.thumb_path)" alt="portfolios-img"></b-img>
                </span>
              </div>
              <div class="col-12 col-md-8">
                <span class="d-flex justify-content-between">
                  <span class="text-secondary font-sm image-name me-2">{{$filters.truncate_start(portfolio.image, 24)}}</span>
                  <span>
                    <div class="dropdown no-arrow">
                      <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="ri-more-2-fill"></i>
                      </a>
                      <ul class="dropdown-menu">
                        <li>
                          <button  @click.prevent="deleteItem(portfolio.id)" class="dropdown-item">
                          <i class="ri ri-delete-bin-line align-bottom me-1"></i> Delete </button>
                        </li>
                      </ul>
                    </div>
                  </span>
                </span>
              </div>
            </div>
          </li>
        </ul>
        <div class="row">
          <div class="col-12 d-flex justify-content-end">
              <div class="text-md-right mt-2 pagination-rounded">
                  <b-pagination v-model="pagination.currentPage"
                  :total-rows="portfolios.length"
                  :per-page="pagination.perPage" @input="onPageChange"></b-pagination>
              </div>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <b-button variant="info" class="mt-2 me-3" @click.prevent="uploadVideoModalShow = true">Add Video</b-button>
          <b-button variant="primary" class="mt-2" @click.prevent="uploadModalShow = true">Add Portfolios</b-button>
        </div>
      </div>
    </div>

    <b-modal v-model="uploadVideoModalShow" hide-footer centered
       title="Upload Video" title-class="font-18">
        <product-video-uploader v-if="uploadVideoModalShow"
         :productId="product.id" @closeMe="initializeAll()" />
    </b-modal>

    <b-modal v-model="uploadModalShow" hide-footer centered
      title="Add Product Portfolios" title-class="font-18">
      <product-file-uploader v-if="uploadModalShow" 
        :productId="product.id" @closeMe="initializeAll()" />
    </b-modal>

    <vue-easy-lightbox :visible="lightbox.visible" :imgs="lightboxFiles"
     :index="lightbox.index" @hide="handleHide"></vue-easy-lightbox>

  </div>
</template>

<script>
import ProductFileUploader from './forms/ProductFileUploader.vue';
import ProductVideoUploader from './forms/ProductVideoUploader.vue';
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  components: {
    ProductFileUploader,
    ProductVideoUploader,
    VueEasyLightbox
  },
  data() {
    return {
      uploadModalShow: false,
      uploadVideoModalShow:false,
      pagination:{
        currentPage: 1,
        perPage: 10,
        startIndex: 0,
        endIndex: 10
      },
      lightbox: {
        visible: false,
        index: null
      }
    }
  },
  computed: {
    product(){
        return this.$store.state.productList.product;
    },
    portfolios(){
      return this.product.portfolios
    },
    paginatedPortfolios(){
      return this.portfolios.slice(
        this.pagination.startIndex,
        this.pagination.endIndex
      );
    },
    lightboxFiles(){
      return this.portfolios.map(file => {
          if(file.is_video){
            return {
                title: file.name,
                autoplay: true,
                thumb: this.absoluteUrl(file.path),
                mediaType: 'video',
                src: this.absoluteUrl(file.video_path)
              }
          }else{
            return {
                title: file.name,
                mediaType: 'image',
                src: this.absoluteUrl(file.path)
              }
          }
      })
  },
  },
  methods: {
    showImg(itemId) {
      this.lightbox.index = this.portfolios.findIndex((item) => item.id == itemId);
      this.lightbox.visible = true;
    },
    handleHide() {
      this.lightbox.visible = false;
    },
    onPageChange() {
      this.pagination.startIndex = (this.pagination.currentPage - 1) * this.pagination.perPage;
      this.pagination.endIndex = (this.pagination.currentPage - 1) * this.pagination.perPage + this.pagination.perPage;
    },
    deleteItem(itemId){
      if(!itemId){return}
      Swal.fire({
        title: "Are you sure?",
        text: "You are about to delete this image!",
        reverseButtons:true,
        showCancelButton: true,
        confirmButtonColor: "#e11",
        cancelButtonColor: "#111",
        confirmButtonText: "Delete it!",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/portfolios/${itemId}/delete`)
          .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.data.success){
              this.$store.commit("productList/DELETE_PORTFOLIO", itemId)
            }
          })
        }
      });
    },
    initializeAll(){
      this.uploadModalShow = false;
      this.uploadVideoModalShow = false;
      this.lightbox.visible = false;
    }
  },
}
</script>

<style scoped>

@media(max-width: 500px){
  .font-sm.image-name{
    font-size: 10px !important;
  }
}

.video-icon-dp{
  height: 25px;
  width: 25px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  line-height: 1.6;
  color: #fff;
  position: absolute;
  background: var(--bs-primary);
  cursor: pointer;
  text-align: center;
  transition: all 0.24s ease-in;
}

.video-icon-dp:hover{
  background: #000;
  color: #fff;
}
</style>
