<template>
  <div>
    <div class="row ">
        <div class="col-12">
            <transition name="fade" mode="out-in">
            <requirement-value-form v-if="enableForm" :value="showValue"
                :requirementId="requirement.id" :editMode="editMode" 
                @created="addItem" @updated="updateItem" @exit="resetForm()"/>
            </transition>
            <b-button v-if="!enableForm" @click="enableForm = true "
            variant="primary" type="button">Add new value</b-button>
        </div>
    </div>
    <div class="row mt-4" v-if="!enableForm">
        <div v-for="(value, tIndex) in requirement.values" 
          :key="tIndex" class="col-lg-6 mb-3">
            <div class="px-3 py-2 rounded bg-white shadow border border-1">
                <div class="flex-grow-1 ">
                    <h5 class="mb-1 mt-0">{{value.name}}</h5>
                    <p style="word-break: break-all;" class="mb-0 small">{{value.value}}</p>
                    <p class="mb-0">Default: {{value.default}}</p>
                </div>
                <hr class="my-1"/> 
                <span class="">
                    <a @click.prevent="initEdit(value)" href class="text-primary me-2">
                    Edit </a>
                    <a  @click.prevent="deleteItem(value.id)" href class="text-danger">
                    Delete </a>
                </span>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import RequirementValueForm from '@/components/forms/RequirementValueForm.vue'
export default {
    components: {
        RequirementValueForm 
    },
    props:{
        showItem:{
            type: Object,
            required: true
        }
    },
    data(){
        return{
            enableForm:false,
            editMode: false,
            showValue:null,
            requirement:{}
        }
    },
    methods:{
        initEdit(value){
            this.showValue = {...value}
            this.enableForm = true
            this.editMode = true
        },
        addItem(item){
            this.requirement.values.unshift(item);
            this.resetForm()
        },
        updateItem(item){
            const dataIndex = this.requirement.values.findIndex((i) => i.id == item.id)
            Object.assign(this.requirement.values[dataIndex], item)
            this.resetForm();
        },
        deleteItem(itemId){
            Swal.fire({
                title: "Are you sure?",
                text: "You are about to delete this requirement!",
                reverseButtons:true,
                showCancelButton: true,
                confirmButtonColor: "#e11",
                cancelButtonColor: "#111",
                confirmButtonText: "Delete it!",
            }).then((result) => {
                if (result.value) {
                this.$store.dispatch("changeLoaderValue",true)
                this.$http.delete(`/product-requirement-values/${itemId}/delete`)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        const ItemIndex = this.requirement.values.findIndex((item) => item.id == itemId)
                        this.requirement.values.splice(ItemIndex, 1);
                        this.resetForm();
                    }
                })
                }
            });
        },
        resetForm(){
            this.editMode = false
            this.enableForm = false
            this.showValue = null
            this.$store.commit("productList/UPDATE_REQUIREMENT", JSON.parse(JSON.stringify(this.requirement)))
        },
    },
    mounted(){
        this.requirement = JSON.parse(JSON.stringify(this.showItem))
    }
}
</script>

<style>

</style>